import styled from "styled-components";
import { device } from "../device";

function Slider() {
  return (
    <LeftContainer>
      <TopSection>
        <Logo src="intent_logo.svg" alt="intent-logo" />
        <InfoContainer>
          <ParaOne>
            Let's build an equitable and sustainable System together.
          </ParaOne>
          <ParaTwo>
            To help us optimise your experience, please sign in
          </ParaTwo>
        </InfoContainer>
      </TopSection>
      <StyledImageContainer>
        <StyledImage src="login_ils.svg" alt="Login-img" />
      </StyledImageContainer>
    </LeftContainer>
  );
}

export default Slider;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  display: none;
  width: 35.47%;
  align-items: center;
  background-color: #111010;
  position: relative;
  @media ${device.laptop} {
    display: flex;
  }
  @media ${device.tablet} {
    display: flex;
  }
`;

const TopSection = styled.div`
  width:100%;
  display: flex;
  flex-direction: column;
  padding: 2.875rem;
`;

const Logo = styled.img`
  width: 5.625rem;
`;

const InfoContainer = styled.div`
  margin-top: 4.9375rem;
  position: fixed;
  top:10vh;
  left:2.5vw;
  width: 32.47%;
`;

const ParaOne = styled.div`
  font-size: 1.75rem;
  font-weight: 500;
  color: #646463;
  line-height: 2.625rem;
`;

const ParaTwo = styled.div`
  width: 75%;
  margin-top: 1.25rem;
  font-size: 1.25rem;
  color: #e6e6e6;
  line-height: 1.875rem;
  font-weight: 500;
`;

const StyledImageContainer = styled.div`
  position: fixed;
  bottom: 7.5rem;
  padding-top: 4.8125rem;
  width: 31.25rem;
  bottom:0;
  left:0;
`;

const StyledImage = styled.img`
  width: 100%;
  position: fixed;
  bottom:0;
  left:0;
  width:40vw;
  height:40vh;
`;

import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import Alert from "@mui/material/Alert";
import EditIcon from "./assets/EditIcon";
import CustomLoading from "../src/components/CustomLoading";
import { device } from "./device";
import OtpVerify from "./components/OtpVerify";
import PhoneInput from "react-phone-input-2";
import Slider from "./components/Slider";
import styled from "styled-components";
import Stack from "@mui/material/Stack";

function App() {
  const url = '/sendOtp';
  const [otpScreen, setOtpScreen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [country, setCountry] = useState("");
  const [errorTxt, setErrorTxt] = useState("");
  const [loadingSt, setLoadingSt] = useState(false);
  const [loginType, setLoginType] = useState("");

  const editPhNumberHandler = () => {
    setOtpScreen(false);
  };

  const onPhoneNumberChange = (value, country) => {
    console.log("Value: ", value);
    console.log("Country: ", country.countryCode);

    setErrorTxt("");
    if (value) {
      setPhoneNumber(value);
      setCountry(country.countryCode);
    } else {
        setPhoneNumber("");
        setCountry(country.countryCode);
    }
  };

  const handleEnterClick = (e) =>{
    if(e.keyCode===13){
      submitNumberHandler(e,"pin");
    }
  }

  let phoneNo = "";
  let countryCode = "";

  if(phoneNumber.length === 13) {
    phoneNo = phoneNumber.slice(3, 13);
    countryCode = "+" + phoneNumber.slice(0, 3);
  } else {
    phoneNo = phoneNumber.slice(2, 12);
    countryCode = "+" + phoneNumber.slice(0, 2);
  }

  localStorage.setItem('username', phoneNo);
  localStorage.setItem('country', country);

  const submitNumberHandler = async (e, type) => {
    setLoginType(type);
    e.preventDefault();
    setLoadingSt(true);

    setLoadingSt(false);
    setOtpScreen(true);
    localStorage.setItem("userNumber", phoneNo);

    if(type === 'otp' || type === 'resend') {
      try {
          await axios.post(url, {
              phoneNo: phoneNo,
              phoneCountryCode: countryCode,
              otpLength: 4
            });
          } catch (error) {
            setOtpScreen(false);
            setErrorTxt(error?.response?.data?.message);
            setTimeout (() => {
                window.location.replace(error?.response?.data?.redirectUri);
            }, 2000);
          }
    }
  };

  useEffect(() => {
    processLoginError()
  });

  const processLoginError = async () => {
    const urlParams = new URLSearchParams(window.location.href);
    const errMsg = urlParams.get('error');
    if (errMsg !== null && errMsg.length > 0) {
      setErrorTxt(errMsg);

      setTimeout (() => {
        const redirect = urlParams.get('redirect');
        window.location.replace(redirect);
      }, 2000);
    }
  };

  const countryNumberLength = (countryCode) => {
    let numLength;
    if(countryCode === "+880") {
            numLength = 13
    }
    else if(countryCode === "+91") {
        numLength = 12
    }
    else if(countryCode === "+84") {
        numLength = 11
    }

    return numLength
  }

  return (
    <Container>
      <Slider image={"/main_bg.png"} alt_t={"login_scr"} />
      <InputContainer>
        <RightContainer>
          <>
            <BigHeading>Sign In</BigHeading>
            <WelcomeHead>
              {!otpScreen
                ? "Please enter your details"
                : loginType !== "pin"
                  ? `Please enter the OTP, sent to your number`
                  : "Please enter the PIN"}
              {otpScreen && (
                <PhoneNumber>
                  {`${countryCode} ${phoneNo}`}
                  <span
                    onClick={editPhNumberHandler}
                    style={{
                      paddingLeft: ".3125rem",
                      cursor: "pointer",
                    }}
                  >
                    <EditIcon />
                  </span>
                </PhoneNumber>
              )}
            </WelcomeHead>
          </>
          <NumberContainer>
              {otpScreen === false ? (
                <OtpScreenContainer>
                    <PhoneNumberText>
                      <div style={{marginBottom: '.5rem'}}>Phone number</div>
                      <PhoneInput
                        onlyCountries={["in", "bd", "vn"]}
                        containerClass="phone-input-container"
                        inputStyle={{
                          background: "#F9F9F9",
                          color: '#2D2D2A',
                          fontWidth: '600',
                          fontSize:'15px',
                          // color: phoneNo ? "green" : "red",
                        }}
                        placeholder="Enter phone number"
                        value={phoneNumber}
                        masks={{
                          in: "..........-........",
                        }}
                        country={"in"}
                        enableLongNumbers={true}
                        countryCodeEditable={false}
                        onChange={onPhoneNumberChange}
                        dropdownStyle={{padding:'.625rem', borderRadius:'.5rem', display:'flex', flexDirection:'column',gap:'10px'}}
                        onKeyDown={handleEnterClick}
                      />
                      {errorTxt && (
                        <Stack sx={{ width: "100%", mt: 2 }} spacing={2}>
                          <Alert severity="error">{errorTxt}</Alert>
                        </Stack>
                      )}
                    </PhoneNumberText>
                </OtpScreenContainer>
              ) : (
                <OtpVerify
                  resend={(e) => submitNumberHandler(e, "resend")}
                  type={loginType}
                />
              )}
          </NumberContainer>
          <OtpButtonContainer >
              {!otpScreen && (
                <OtpBtn
                  disabled={
                    phoneNumber?.length === countryNumberLength(countryCode)
                      ? false
                      : true
                  }
                  onClick={(e) => submitNumberHandler(e, "otp")}
                >
                  {loadingSt ? <CustomLoading size={"1.2rem"} /> : "Send OTP"}
                </OtpBtn>
              )}
              {!otpScreen && (
                <OtpBtn
                  disabled={
                    phoneNumber?.length === countryNumberLength(countryCode)
                      ? false
                      : true
                  }
                  onClick={(e) => submitNumberHandler(e, "pin")}
                >
                  {loadingSt ? (
                    <CustomLoading size={"1.2rem"} />
                  ) : (
                    "Login with Pin"
                  )}
                </OtpBtn>
              )}
          </OtpButtonContainer>
        </RightContainer>
      </InputContainer>
    </Container>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  height: 100vh;
  @media ${device.laptop} {
    background-color:#F0F0F0;
  }
  @media ${device.tablet} {
    background-color:#F0F0F0;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width:100%;
  @media ${device.laptop} {
    padding-top: 0px;
  }
  @media ${device.tablet} {
    padding-top: 0px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media ${device.laptop} {
    background-color: #FFFFFF;
    width: 36.875rem;
    height: 28.0625rem;
    padding: 2.375rem 2.5rem;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    border-radius: .75rem;
  }
  @media ${device.tablet} {
    background-color: #FFFFFF;
    padding: 2.375rem 2.5rem;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.10);
    border-radius: .75rem;
  }
`;

const BigHeading = styled.p`
  font-weight: 600;
  font-size: 2rem;
  color: #18A0FB;
  line-height: 2.5rem;
  margin-bottom: 0px;
`;

const WelcomeHead = styled.div`
  font-weight: 500;
  font-size: 1.125rem;
  color: #898884;
  margin: .6875rem 0px 1.875rem 0px;
  letter-spacing: 0.54px;
  line-height: 30px;
`;

const PhoneNumber = styled.div`
  color: #18A0FB;
  fontWeight: 600;
  fontSize: 1.125rem;
  display:flex;
  alignItems: center;
  letter-spacing: 0.54px;
`;

const NumberContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const OtpScreenContainer= styled.div`
  display:flex;
  flexDirection:column;
`;

const PhoneNumberText = styled.h2`
  font-size: .875rem;
  color: #898884;
  letter-spacing:0.7px;
  width:100%;
`;

const OtpButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap:.625rem;
  margin-top:2.5rem;
`;

const OtpBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18.75rem;
  min-height: 1.25rem;
  padding: 1rem 1.25rem;
  font-weight: 600;
  font-size: .9375rem;
  border-radius: .5rem;
  outline: none;
  background:#FFFFFF;
  color: ${(props) => (props.disabled ? "#8E8E8E" : "#18A0FB")};
  border:${(props) => (props.disabled ? "2px solid #8E8E8E" : "2px solid #18A0FB")};
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : 'pointer')};
  &:hover {
    background: ${(props) => (props.disabled ? "#FFFFFF" : "#18A0FB")};
    color: ${(props) => (props.disabled ? "#8E8E8E" : "#FFFFFF")};
  }
`;

import Alert from "@mui/material/Alert";
import CustomLoading from "./CustomLoading";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import styled from "styled-components";
import Stack from "@mui/material/Stack";

const OtpVerify = (props) => {
  const { resend, type } = props;
  const [loadingSt] = useState(false);
  const [otp, setOTP] = useState("");
  const [username, setUsername] = useState("");
  const [errorTxt, setErrorTxt] = useState("");

  const handleChange = (otp) => {
    setUsername(`${localStorage.getItem("country")}:${localStorage.getItem("username")}`);
    setOTP(otp);
    setErrorTxt("");
  };

  return (
    <form action="/login" method="post">
        <input hidden type="text" name="username" value={username} />
        <input hidden type="text" name="password" value={otp} />
        <VerifyDiv>
          {type === "pin" && <PinContainer>Enter Pin</PinContainer>}
          <OtpElements>
                <OTPInput
                  shouldAutoFocus={true}
                  onChange={handleChange}
                  value={otp}
                  inputStyle="inputStyle"
                  isInputNum={true}
                  numInputs={4}
                  separator={<span></span>}
                />
                {errorTxt && (
                  <Stack sx={{ width: "100%", mt: 2 }} spacing={2}>
                    <Alert severity="error">{errorTxt}</Alert>
                  </Stack>
                )}
              </OtpElements>
              <ResendContainer>
                {type !== "pin" && (
                  <>
                    Did not receive an OTP?
                    <ResendBtn className="resendBtn" onClick={resend}>
                      Resend
                    </ResendBtn>
                  </>
                )}
              </ResendContainer>
              <VerifyOtpContainer>
                  <VerifyOtpBtn
                    className="loginButton"
                    disabled={otp?.length === 4 ? false : true}
                    type="submit">
                    {loadingSt ? <CustomLoading size={"1.2rem"} /> : `Verify`}
                  </VerifyOtpBtn>
              </VerifyOtpContainer>
        </VerifyDiv>
    </form>
  );
};

export default OtpVerify;

const VerifyDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const OtpElements = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const PinContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px .9375rem 0px;
  color: #898884;
  font-size: .875rem;
  font-weight: 700;
  width: 80%;
`;
const ResendContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5625rem 0px .9375rem 0px;
  color: #898884;
  font-size: .875rem;
  font-weight: 600;
  width: 80%;
`;
const ResendBtn = styled.p`
  color: #18a0fb;
  font-weight: 700;
  font-size: .875rem;
  cursor: pointer;
  margin-top: .3125rem;
`;
const VerifyOtpContainer = styled.div`
  width: 100%;
`;
const VerifyOtpBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .5rem;
  width: 18.75rem;
  min-height: 1.25rem;
  padding: 1rem 1.25rem;
  font-weight: 600;
  font-size: .9375rem;
  outline: none;
  background: #ffffff;
  color: ${(props) => (props.disabled ? "#8E8E8E" : "#18A0FB")};
  border: ${(props) =>
    props.disabled ? "2px solid #8E8E8E" : "2px solid #18A0FB"};
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background: ${(props) => (props.disabled ? "#FFFFFF" : "#18A0FB")};
    color: ${(props) => (props.disabled ? "#8E8E8E" : "#FFFFFF")};
  }
`;